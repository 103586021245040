<template>
  <div>
    <div class="panel_op">
      <el-button
        type="primary"
        size="small"
        @click="rowClick"
      >
        新增资讯
      </el-button>
    </div>
    <div class="panel_table">
      <el-table
        v-loading="loading"
        :data="tableData"
        :header-cell-style="headerCellStyle"
        @row-click="rowClick"
      >
        <el-table-column
          label="序号"
          type="index"
          width="100"
          :index="indexMethod"
        >
        </el-table-column>
        <el-table-column
          prop="title"
          label="标题"
        > </el-table-column>
        <el-table-column
          prop="createdAt"
          label="发布时间"
        > </el-table-column>
        <el-table-column
          label="状态"
          width="200px"
        >
          <template slot-scope="scope">
            <el-tag
              :type="
                ['primary', 'success', 'danger' ][
                  scope.row.status
                ] || 'primary'
              "
              disable-transitions
            >{{ globalVar.INFO_STATUS_OPTIONS[scope.row.status].label }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          width="100px"
          label="操作"
        >
          <template slot-scope="scoped">
            <span
              v-if="scoped.row.status == globalVar.INFO_STATUS_PUBLISHED || scoped.row.status === globalVar.INFO_STATUS_FAILED"
              class="text_color_danger tw-cursor-pointer"
              @click.stop="deleteBefore(scoped.row, scoped.$index)"
            >删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="panel_pagination tw-flex tw-items-center tw-justify-center">
      <el-pagination
        hide-on-single-page
        background
        @current-change="getTableData"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import globalVar from '@/configs/globalVar.js'
import * as api from '@/api'
export default {
  props: {
    // 栏目id
    id: String
  },
  data () {
    return {
      // INFO_STATUS_OPTIONS: globalVar.INFO_STATUS_OPTIONS,
      // 页面loading
      loading: false,
      // 资讯过滤条件
      search: {
        // 资讯列表只包含'已发布'状态
        status: '1',
        keywords: ''
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }, // 分页对象
      tableData: []
    }
  },
  computed: {
    headerCellStyle () {
      return this.$store.state.headerCellStyle
    }
  },
  created () {
    // 获取某一栏目下的信息列表
    this.getTableData()
  },
  methods: {
    getTableData () {
      this.loading = true
      api
        .getInfoList(this.id, {
          ...this.pagination, ...this.search
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.pagination.pageNum = res.data.data.currentPage
            this.pagination.total = res.data.data.total
            this.tableData = res.data.data.list
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err)
          this.$message.error('请求出错')
        })
        .finally(() => {
          this.loading = false
        })
    },

    // 删除资讯
    deleteBefore (item, index) {
      // eslint-disable-next-line eqeqeq
      if (item.status == this.INFOSTATUS[0]) {
        return
      }
      this.$msgbox({
        title: '删除资讯',
        message: '确定要删除该资讯？',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.deleteInfo(item, index, instance, done)
          } else {
            done()
          }
        }
      })
    },

    // 删除
    deleteInfo (item, index, instance, done) {
      instance.confirmButtonLoading = true
      api
        .deleteInfo(item.id)
        .then((res) => {
          if (res.data.code === 0) {
            console.log('删除资讯成功', res.data.data)
            this.$message.success('删除成功！')
            this.tableData.splice(index, 1)
          } else {
            this.$message.error(res.data.message)
          }
          done()
        })
        .catch((err) => {
          console.log(err)
          this.$message.error('请求出错')
        })
        .finally(() => {
          instance.confirmButtonLoading = false
        })
    },

    // 点击行
    rowClick (row) {
      // 资讯列表中存在的都是‘已发布状态’
      const id = row.id ? row.id : ''
      const { href } = this.$router.resolve({
        path: `/info-manage/info-list/info-form/${id}`
      })
      window.open(href, '_blank')
    },

    indexMethod (index) {
      return (
        this.pagination.pageSize * (this.pagination.pageNum - 1) + index + 1
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  width: 100%;
  min-width: 1030px;
  min-height: 100%;
  padding: 0 30px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: auto;
  &_header {
    padding: 20px 0;
  }
  &_op {
    padding: 10px 0;
  }
  &_table {
    padding: 10px 0;
  }
  &_pagination {
    margin: 20px 0;
    height: 40px;
  }
}
</style>
